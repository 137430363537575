.lead{
    font-size: 26px;
    text-align: center;
    
}
.sub-content{
    font-size: 18px;
    text-align: center;
    text-wrap: pretty;
}
.terms li{
    font-size: 16px;
    margin:6px 2px;
}
.terms p{
    margin: 6px 2px;
}
.terms h3, h4{
    text-align: center;
}
.private li{
    font-size: 16px;
    margin:6px 2px; 
}
.private h4{
    text-align: center;
}
.private p{
    margin: 6px 2px;
}