/* .sidebar {
    background-color:inherit;
    padding: 20px;
    width: 250px;
    height: 80vh;
    top: 40%;
    left: 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
   
  } */
  
  /* .sidebar ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .sidebar li {
    margin-bottom: 10%;
    
    font-size: 18px;
  }
  
  .sidebar a {
    text-decoration: none;
    color: #337ab7;
  }
  
  .sidebar a:hover {
    color: #23527c;
  } */
  .sidebar li {
  
    font-family: DM Sans;
    font-size: 18px;
    font-weight: 500;
    line-height: 14px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    
    
  }
  .letter{
    background: rgba(26, 31, 55, 1);
    
    height: auto; 
    border-radius: 15px;
    opacity: 1;
    padding: 10px;

  }
  
  hr{
            width: 100%; /* Adjust the width as needed */
            margin: 20px auto; /* Center the line with margin */
            border: 1px solid; /* Set the border */
            border-image-source: linear-gradient(90deg, rgba(224, 225, 226, 0) 0%, #E0E1E2 49.52%, rgba(224, 225, 226, 0.15625) 99.04%);
            border-image-slice: 1;
    
  }
  .bag{
    background: rgb(34, 35, 36);
    color:  rgba(0, 117, 255, 1);
    padding: 10px;
    border-radius: 12px;

  }
  .flag{
    background: rgba(0, 117, 255, 1);
    color:  rgb(243, 246, 248);
    padding: 14px;
    border-radius: 10px;
    font-size: 16px;
  }
  .tube{
    width:360px;
    height: 80px;
    top: 138px;
    left: 407px;
    border-radius: 8px;

  }
  .welcome_card{
    background: linear-gradient(159.02deg, #0F123B 14.25%, #090D2E 56.45%, #020515 86.14%),url('../assets/jellyfish.svg');
    background-size: cover;
    background-blend-mode: overlay;
  
    height:200px;
  }
  .gauge_chart{
    width: 250px;
    height: 225px;
    gap: 0px;
    border-radius: 20px;
  }
  .gauge-chart-gradient {
    background: inherit;
    border-radius: 10px; /* Optional: for rounded corners */
    height: 200px; /* Set a height for the gauge chart */
    width: 200px; /* Set a width for the gauge chart */
  }
  .gauge-container {
    
    /* Use absolute positioning */
    top: 261px;
    left: 969px;
    gap: 8px; /* Note: gap is typically used in flex/grid containers */
    border-radius: 20px;
    border: transparent;
    opacity: 1; /* Fully transparent */
    background: linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%);
  }
  .apexcharts-tooltip {
    background: inherit;
    color: orange;
  }
  .apexcharts-toolbar{
    display: none !important;
    color: black;
  }
  .apexcharts-legend-marker{
    color:#E0E1E2 !important;
  }