.admin-container {
    display: flex;
    flex-direction: row;
  }
  
  .sidebar {
    width: 200px;
    background-color:inherit;
    padding: 20px;
    border-right:none;
  }
  
  .main-content {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .admin_portal{
    background: linear-gradient(to right,rgb(1,6,15), rgb(0,22,61), rgb(1,26,68));
  }