.singleBlog{
    width:100%;
    height:100%;
    background: #fff;
}
.article-header{
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--blue-color);
        font-family: Inter;
        font-size: 22px;
        text-decoration: underline;
        font-weight: 600;
        margin-top: 5%;
}
h6{
        text-align: center;
        font-size: 22px;
        color:var(--blue-color);
}

.article-container{
        display:block;
        background:var(--white-color);
        border-radius: 12px;
        margin: 1rem auto;

        
}
.mini{
        display: flex;
        justify-content: center;
        align-items: center;
        width:100%;
}
.read{
        display: flex;
    justify-content: center;
    background: #fff;
    border-radius: 14px;
    margin-top: 5%;
    width: 60%;
}
.read-text{
        display: block;
}
.read p{
        color:black;
        font-size: 12px;
        text-wrap: pretty;
}
.read-button{
        background-color: var(--blue-color);
        color:white;
        display: inline-block;
}
#single{
        background: #fff;
}

@media (max-width:680px){
        .article{
                display: block;
                text-align: center;
                height:100%;
                width:100%;
                text-wrap:balance;
                font-size: 16px;
                padding:6px;
                font-style:normal;
        }
        
        
               .blogs{
                display: block;
               }
               .features-card-blog{
                display:block;
               }
              
               
               .blogs-card-container{
                display:block;
                width:100%;
                
               }
              
               .blogs-card{
                display: block;
                width:100%;
                margin:1rem 7rem;
                
               }

}