.home{
    height:100%;
    width:100%;

}


.h1-text{
    text-align:center ;
    text-transform: uppercase;
    margin: auto;
    font-family: Inter;
    font-size: 58px;
    font-weight: 400;
    line-height: 70.14px;
    letter-spacing: -3.82669997215271px; 
    text-transform: capitalize;
    color:var(--blue-color);
}
.ender h2{
    font-family:Manrope;
    font-size:30px;
}




.pop-up img{
    width:890px;
    height:900px;
}


.hero-section {
    position: relative;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    z-index: 1; /* Add this to prevent scrolling */
  }
  .hero-button button {
    padding: 20px;
    border-radius: 12px;
    border:2px solid #125DF4;
    background: transparent;
    color: #fff;
    text-wrap:nowrap;
    font-size: 16px;
    margin-top:1rem;
    position:relative;
    z-index:1000;
  }
  .hero-button button:hover{
    background: #125DF4 !important;
  }
  
  .hero-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  video {
    object-fit: cover;
    width: 100%;
    height: 100vh !important;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    mix-blend-mode: lighten;
    pointer-events: none;
  }
  
  .hero-text {
    position: relative;
    color: #fff;
    z-index: 1; /* Make sure the text is on top of the video */
    font-size: 48px; /* Add a font size to make the text visible */
    text-align: center;
  }
  .board{
    background: #070713;
    border-radius: 8px;
    opacity: 0.8;
  }

  .grade{
    background:linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.4));
    background-clip: text;
    -webkit-background-clip: text; 
    -webkit-text-fill-color: transparent; 
    
  }
  .sound img{
    width:142px;
    height:142px;
  }
  .network img{
    width:225px;
    height:315px;
  }
  .theme{
    background: url(../assets/donut.svg) no-repeat top;
    background-size: 600px 400px;
    width: 100%;
    height: 100%;
    position:relative;
  }
  
.theme::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  filter: blur(10px);
  z-index: -1;
}
.pay_area{
  width:100%;
  height:600px;
  background: url(../assets/pay.png);
  background-size: 1104px 1920px;
}
.pay_area_md{
  width:100%;
  height:500px;
  background: url(../assets/pay.png);
  background-size: 524px 911px;
}
.pay_area_sm{
  width:100%;
  height:400px;
  background: url(../assets/pay.png);
  background-size: 222px 375px;
}
.blue{
  height: 400px;
}
.blue_md{
 height: 350px;
}
.blue_sm{
 height: 250px;
}
.store_sm{
  font-size:35%;
}
.apple{
  background: #0f766e;
  width:16px;
  height:16px;
  border-radius: 50%;
}
.apple_sm{
  background: #0f766e;
  width:8px;
  height:8px;
  border-radius: 50%;
}
#trial {
  display: grid;
  place-items: center;
  height: 100%; /* or set a specific height */
}
#phone {
  position: relative;
}

#thumbs {
  position: absolute;
  top:0;
  left:0;
  width:150px;
  height:150px;
}
#orb{
  position: absolute;
  bottom:0;
  right:0;
  width:100px;
  height:100px;
}

#phone_md {
  position: relative;
}

#thumbs_md {
  position: absolute;
  top:0;
  left:0;
  width:125px;
  height:125px;
}
#orb_md{
  position: absolute;
  bottom:0;
  right:0;
  width:75px;
  height:75px;
}

#phone_sm {
  position: relative;
}

#thumbs_sm {
  position: absolute;
  top:0;
  left:0;
  width:50px;
  height:50px;
}
#orb_sm{
  position: absolute;
  bottom:0;
  right:0;
  width: 30px;
  height:30px;
}
.community{
  height:100%;
}
.group{
  background: url("../assets/group_sphere.png") no-repeat top left;
  background-size: 400px 533px;
  
}
#indicate{
  
  width:10px;
}
.sub{
  color:#b3b3b3;
}
.mail{
  background:transparent;
  border:1px grey solid;
  border-radius:6px;
  padding:8px;
}