.carousel{
    height:100vh;
   
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    
}
.carousel-wrapper{
    position:relative;
    width:100%;
    height:100%;
}
.carousel_card{
  display:flex;
  flex:1;
  position: absolute;
  width:100%;
  height:100vh;
  
  justify-content: center;
  overflow:hidden;
  border-radius:20px;
  margin-top:5%;
  margin-bottom:10%;
  opacity:0;
  pointer-events: none;
  transform:translateX(-50px);
  transition: 1s ease-in-out;
}
.carousel_card_active{
    opacity:1;
    transform:scale(1);
    pointer-events: visible;
}
.cards_image{
   width:100%;
   
   object-fit:contain;
   
}
.pagination_dot{
    color:#475569;
}
.pagination_dot_active{
    color:#94a3b8;
}

