

 
 .navbar{
    z-index:2;
    position:fixed;
    top: 8px;
    border-radius: 28px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width:100%;
    
 }
 .navbar-brand{
    width:90px;
 }
 .navbar-brand img{
   width:226px;
 
}

 .navbar-brand p{
    margin-left: 0.2rem;
    font-size: 22px;
 }
 .navbar-content{
   display: flex;
   width: 100%;
   justify-content: space-between;
}
 .brand-and-toggler{
    width:100%;
 }
 
 .navbar-toggler-btn{
    transition: var(--transition);
    z-index: 11;
    display:block;
 }
 .navbar-collapse{
    position:fixed;
    right:0;
    top:0;
    z-index: 10;
    background-color: var(--black-color);
    opacity: 1;
    height:90vh;
    width:300px;
    border-radius: 16px;
    display: flex;
    padding:8rem 3rem 0 4rem;
    transform: translateX(100%);
 }
 
 .show-navbar-collapse{
    transform: translateX(0);
 }
 .nav-item{
    margin-bottom: 0.8rem;
    font-size: 18px;
    margin-right: 2rem;
 }
 .nav-link{
    transition: var(--transition);
 }

#faBars{
    padding:12px;
}

@media screen and (min-width: 992px){
    .navbar-collapse{
        position: relative;
        height:auto;
        padding:0;
        width:100%;
        background: transparent;
        transform: translateX(0);
        justify-content: flex-end;
    }
    .navbar-toggler-btn{
        display: none;
    }
    .nav-item{
        margin-bottom: 0;
        margin-left: 2.8rem;
    }
   
    
    .navbar-nav{
        display:flex;
    }
    
   
}


 

