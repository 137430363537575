.contact{
    height:100%;
    width:100%;
    font-family: 'Poppins';
}
.form_field input{
   margin-top: 1px;
   border-bottom: 2px solid #8D8D8D;
   ;
   height:20px;
   padding:20px;
   font-family: Poppins;
   font-size: 16px;
}
.form_field label{
    
    font-family: Poppins;
}
::placeholder{
    color:rgb(71 85 105);
    font-size: 12px;
    margin-bottom: 4px;
}
.radio_field label{
    color:rgb(71 85 105);
    font-size: 14px;
    font-family: Poppins;
    font-weight: 500;
}
.message_area input{
    margin-top: 1px;
   border-bottom: 2px solid#8D8D8D;
   ;
   height:20px;
   padding:20px;
   color: #010101;
   font-family: Poppins;
   font-size: 16px;
}
#letter{
    height:90%;
    width:240px;
}
select{
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: #010101;
}
select > option{
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: #010101;  
}
#send_button{
    background: #1c58e3;
    opacity: 1;
    padding: 15px 48px 15px 48px;
    border-radius: 5px 0px 0px 0px;


}

.form-field input{
    margin-top: 1px;
    border: 2px solid #8D8D8D;
    ;
    height:20px;
    padding:20px;
    font-family: Poppins;
    font-size: 16px;
    border-radius: 4px;
    background:transparent;
    
 }
 .form-field label{
    
    font-family: Poppins;
    font-size: 18px;
}
.delete img{
    width:220px;
    height:100px;
}
.delete button {
    padding: 20px;
    border-radius: 12px;
    border: 2px solid #125df4;
    background: transparent;
    color: #fff;
    text-wrap: nowrap;
    font-size: 16px;
    margin-top: 1rem;
  }
  .delete button:hover {
    background: #125df4;
  }
  .login_container{
    border-radius: 28px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  .login_container input{
    background: transparent;
    font-size: 18px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    height:16px;
    padding:18px;
    border-radius: 6px;
  }
  .login_container label{
    color:#8D8D8D;
  }
  .logged button{
    padding: 16px;
    border-radius: 12px;
    border: 2px solid #125df4;
    background: transparent;
    color: #fff;
    text-wrap: nowrap;
    font-size: 16px;
    margin-top: 1rem;
  }
  .logged button:hover {
    background: #125df4;
  }