.blog{
    height:100%;
    width:100%;
    font-family: 'Poppins';
}
.blog-card{
    background: rgba(255, 255, 255, 0.2);
    border-radius: 18px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}
.blog-card img{
    object-fit: contain;
    border-radius:18px;
}
.searchbar-container{
    background: transparent;
    border-radius: 12px;
    border: 2px solid #125DF4;
    padding:12px 2px;

    
}
.searchbar-container input{
    background: transparent;
    
}
.searchbar-container input::placeholder{
    color: #64748b;
    padding-left: 12px;
}
.searchbar-container .icon{
    float: left;
}

