
 .footer{
    background:#070713;
} 

.footer-copyright p{
    font-family:Manrope;
    font-size:16px;
}
.footer-copyright img{
    display: inline-block;
    width:120px;
    height:50px;
}
#write-up{
    font-size:20px;
    width:60px;
    height:20px;
    color: rgb(168 162 158);
}

