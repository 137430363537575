/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://rsms.me/inter/inter.css');


:root {
  --navy-color: #041638;
  --blue-color: #125df4;
  --grey-color: #f3f3f3;
  --white-color: #fff;
  --black-color: #010101;
  --light-black-color: #717171;
  --transition: all 300ms ease-in-out;
}
* {
  margin: 0;
  padding: auto;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}
body {
  font-size: 1.6rem;
  line-height: 1.7;
  font-family: 'Inter', sans-serif;
  color: var(--white-color);
  background: #070713;
  overflow-x: hidden;
}

ul {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: var(--white-color);
  font-weight: 700;
}
a:hover {
  color: var(--navy-color);
}

img {
  width: 100%;
  display: block;
}

input {
  border: none;
  outline: 0;
  font-family: inherit;
  font-size: 1.6rem;
}
.resize-animation-stopper {
  animation: none !important;
  transition: none !important;
}

.ls-1 {
  letter-spacing: 1px;
}
.ls-2 {
  letter-spacing: 2px;
}
.flex {
  display: flex;
  align-items: center;
}
.flex-column {
  flex-direction: column;
}
.flex-sb {
  justify-content: space-between;
}
.flex-c {
  justify-content: center;
}
.grid {
  display: grid;
}

.fs-15 {
  font-size: 1.5rem;
}
.fs-16 {
  font-size: 1.6rem;
}
.fs-17 {
  font-size: 1.7rem;
}
.fs-18 {
  font-size: 1.8rem;
}
.fs-20 {
  font-size: 2rem;
}
.fs-22 {
  font-size: 2.2rem;
}
.fs-24 {
  font-size: 2.4rem;
}
.fs-26 {
  font-size: 2.6rem;
}

.fw-3 {
  font-weight: 300;
}
.fw-4 {
  font-weight: 400;
}
.fw-5 {
  font-weight: 500;
}
.fw-6 {
  font-weight: 600;
}
.fw-7 {
  font-weight: 700;
}
.fw-8 {
  font-weight: 800;
}
.fw-9 {
  font-weight: 900;
}
/*buttons*/

.list.button {
  padding: 20px;
  border-radius: 12px;
  border: 2px solid #125df4;
  background: transparent;
  color: #fff;
  text-wrap: nowrap;
  font-size: 16px;
  margin-top: 1rem;
}
.list.button:hover {
  background: #125df4;
}

/* ender section */
.ender {
  background: url("../src/assets/twinner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.next {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 18px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.card {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3); /* same background as navbar */
  border-radius: 12px;
  padding: 20px;
  transition: background-color 300ms ease-in-out;
  width: 255px;
  height: 355px;
  /* add transition effect */
}

.card:hover {
  background-color: rgba(200, 200, 200, 0.2); /* a shade darker than #070713 */
}
.card p{
  font-size: 14px;
  font-weight: 200;
  font-family: "DM Sans";
  /* line-height: 21.25px; */
  color: #979595;
}
.card img{
  width:24px;
  height:24px;
}
.content{
  padding-top:28px;
}
.content p{
  color:#ffffff;
  font-size: 12px;
  line-height: 30px;
  margin-top:24px;
}
.content span{
  color:#ffffff;
  font-size: 24px;
  margin-top:24px;
  margin-bottom:22px;
}
